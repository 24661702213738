import React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import QualificationCard from '../components/QualificationCard';
import Metadata from '../components/Metadata';
import { StyledContainer } from '../layouts/styles';
import { StaticImage } from 'gatsby-plugin-image';

const StyledQualificationList = styled.ul`
  list-style-type: none;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 0;
  justify-content: center;
  margin-top: 2rem;

  > li {
    text-deocration: none;
    margin-bottom: 2rem;
    width: 100%;

    @media (min-width: 576px) {
      max-width: 20rem;
    }
  }
`;

const StyledHeadingImage = styled.div`
  @media (min-width: 920px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  gap: 3rem;
`;

function Leitung() {
  return (
    <>
      <Metadata title='Gründerin & Leitung' />
      <StyledContainer>
        <h1>Gründerin & Leitung</h1>
        <Section>
          <StyledHeadingImage>
            <div>
              <p>
                Mein Name ist Sabrina Liebich. Ich freue mich sehr, dass Sie den
                Weg zu uns gefunden haben.
              </p>
              <p>
                In meiner beruflichen Laufbahn durfte ich viele verschiedene
                Arbeitsbereiche kennenlernen und im Austausch mit autistischen
                Personen und unterschiedlichen Berufsgruppen sein. Diese
                Erfahrungen haben mein professionelles Handeln und auch meine
                Ansprüche an die Angebote für Menschen mit Autismus und ihr
                Umfeld geprägt.
              </p>
              <p>
                Fachliche Expertise und ein aufrichtiges Interesse an meinen
                Klient:innen sind die Basis für meine Angebote, die autistische
                Personen und ihre Angehörigen dabei unterstützen können,
                Barrieren zu überwinden und ihre individuellen Ziele
                selbstbestimmt zu erreichen.
              </p>
            </div>
            <StaticImage
              src='../images/sabrina_liebich-page.jpg'
              alt='Bild von Sabrina Liebich'
            />
          </StyledHeadingImage>
        </Section>
        <Section>
          <h2>Hintergrund</h2>
          <p>
            Bevor ich Lernen mit Rückenwind gegründet habe, arbeitete ich als
            fachliche Leitung für zwei Standorte eines Therapiezentrums für
            autistische Kinder und Jugendliche. Dort war ich mehrere Jahre für
            die Beratung von Familien, KiTas und Schulen, die Ausarbeitung der
            Förderprogramme sowie die Mitarbeiterschulung zuständig.
          </p>
          <p>
            Weitere Stationen in meiner beruflichen Laufbahn waren die Arbeit in
            einer Grundschule, wo Schüler und Schülerinnen mit und ohne
            Behinderungen beschult wurden. Ich war als Inklusionsassistenz tätig
            und habe als Co-Therapeutin Therapieprogramme im häuslichen Umfeld
            umgesetzt. Im Familienunterstützenden Dienst betreute ich Kinder mit
            Behinderungen und sammelte zuvor Berufserfahrung als Erzieherin in
            einer integrativen Kindertagesstätte.
          </p>
          <p>
            Neben meiner über 10jährigen Erfahrung in der Arbeit mit
            autistischen Kindern und Jugendlichen, habe ich im Bereich des
            ambulant betreuten Wohnens mit erwachsenen Menschen mit Autismus
            zusammengearbeitet.
          </p>
          <p>
            Aus Interesse an der Wissenschaft und Freude an der Lehre, war ich
            mehrere Semester als Lehrbeauftragte für zwei Hochschulen im Bereich
            der verhaltensorientierten Sozialen Arbeit und der Psychologie
            tätig.
          </p>
        </Section>
        <Section
          heading='Qualifikationen'
          maxWidth='120ch'
          backgroundColor='primarys'
        >
          <StyledQualificationList>
            <li>
              <QualificationCard year='aktuell' title='Studium'>
                Master of Science der Psychologie kindlicher Lern- und
                Entwicklungsauffälligkeiten
              </QualificationCard>
            </li>
            <li>
              <QualificationCard year='2021' title='Legasthenietrainerin'>
                Diplomierte Legasthenietrainerin (EÖDL)
              </QualificationCard>
            </li>
            <li>
              <QualificationCard year='2020' title='IBA'>
                International Behavior Analyst
              </QualificationCard>
            </li>
            <li>
              <QualificationCard year='2019' title='BCBA'>
                Board Certified Behavior Analyst
              </QualificationCard>
            </li>
            <li>
              <QualificationCard year='2018' title='M.A.'>
                Master of Arts in Applied Behavior Analysis with Emphasis in
                Autism
              </QualificationCard>
            </li>
            <li>
              <QualificationCard year='2015-2019' title='BCaBA'>
                Board Certified assistant Behavior Analyst
              </QualificationCard>
            </li>
            <li>
              <QualificationCard year='2013' title='B.A.'>
                Bachelor of Arts in Heilpädagogik
              </QualificationCard>
            </li>
            <li>
              <QualificationCard year='2008' title='Erzieherin'>
                Staatlich anerkannte Erzieherin
              </QualificationCard>
            </li>
          </StyledQualificationList>
        </Section>
        <Section>
          <h2>Weiterbildungen</h2>
          <p>
            Meine persönliche und fachliche Weiterentwicklung ist mir ein großes
            Anliegen. Die folgende Liste zeigt eine Auswahl von Themenbereichen,
            zu welchen ich mich intensiver fortgebildet habe:
          </p>
          <ul>
            <li>Förderung der Sprache in früher Kindheit</li>
            <li>Motorische Entwicklung und Förderung</li>
            <li>
              Spiel- und Sozialverhalten von Kindern mit Entwicklungsstörungen
            </li>
            <li>
              Toilettengänge und Hygiene bei Menschen mit hohem
              Unterstützungsbedarf
            </li>
            <li>
              Bewertung und Behandlung von <br />
              Nahrungsmittelselektivität / Fütterstörungen
            </li>
            <li>Behandlung von Schlafstörungen</li>
            <li>
              Fördern von Problemlösestrategien und kognitiver Flexibilität
            </li>
            <li>Pubertät und Sexualität von Menschen mit Behinderungen</li>
            <li>
              Personenzentrierte Behandlung von autistischen
              Kindern/Jugendlichen mit eingeschränkter Kommunikation
            </li>
            <li>Trauma und Bindung</li>
            <li>Krisenintervention</li>
            <li>Acceptance and Commitment Therapy (ACT)</li>
            <li>Lösungsorientiertes Coaching (IFBC)</li>
            <li>
              Psychosoziale Behandlung nach dem Modell „Collaborative &
              Proactive Solutions“ (CPS)
            </li>
          </ul>
        </Section>
      </StyledContainer>
    </>
  );
}

export default Leitung;
