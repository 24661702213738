import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border: var(--border-small);
  border-radius: 0.5rem;
  position: relative;
  padding-inline: 1rem;
  min-height: 200px;
  background-color: white;

  h2 {
    align-self: center;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }

  p {
    margin-top: 0;
  }
`;

const StyledCardYear = styled.span`
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 9;
  border: var(--border-small);
  padding: 0.5rem;
  background-color: var(--color-primary);
  color: white;
  border-radius: 0.5rem;
`;

function QualificationCard({ year, title, children }) {
  return (
    <StyledCard>
      <StyledCardYear>{year}</StyledCardYear>
      <h2>{title}</h2>
      <p>{children}</p>
    </StyledCard>
  );
}

export default QualificationCard;
